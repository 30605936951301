import {Module} from "vuex"
import {RootState} from "../types"

import {Policyholder} from "./types"
import diff, {Diff} from "lib/date/diff"
import {LegalCommunication} from "lib/types/personal/participant"
import { isNull, isUndefined } from "lodash-es"

const policyholderState: Policyholder = {
	civilStatus: "SINGLE",
	contact: {
		address: undefined,
		email: undefined,
		telephone: undefined
	},
	id: "",
	relationNumber: "",
	policies: [],
	personal: {
		birthdate: new Date(),
		lastName: "",
		firstName: undefined,
		gender: "THIRD",
		initials: undefined,
		prefix: undefined
	},
	partners: [],
	identificationNumber: "",
	employments: [],
	divorcedPast: undefined,
	externalId: undefined,
	financial: undefined,
	widowedPast: undefined,
	riskProfileCode: undefined,
	netYearlySalary: 0,
	grossYearlySalary: 0,
	communication: "OFFLINE",
	fiscalRoomYearly: 0,
	retired: false,
	fiscalRoomOneTime: 0,
	inPondresFile: false
}

const policyholder: Module<Policyholder, RootState> = {
	actions: {
		hydrate(state, data: Policyholder) {
			Object.assign(state, data)
		}
	},
	getters: {
		isRetired: state => state.retired,
		inPondresFile: state => state.inPondresFile,
		age: state => diff(Diff.MONTHS, state.personal.birthdate, new Date()) / 12,
		currentPartner: state => state.partners ?
			state.partners.filter(partner => isNull(partner.civilStatus.endDate) || isUndefined(partner.civilStatus.endDate))[0] :
			undefined,
		currentEmployments: state => state.employments.filter(employment => isUndefined(employment.endDate) || isNull(employment.endDate)).sort(
			(employmentA, employmentB) => employmentB.startDate.valueOf() - employmentA.startDate.valueOf()),
		netMonthlySalary: state => Math.round(state.netYearlySalary / 12),
		formattedLastName(state) {
			if (isNull(state.personal.prefix) || state.personal.prefix === "" || isUndefined(state.personal.prefix)) {
				return `${state.personal.initials} ${state.personal.lastName}`
			} else {
				return `${state.personal.initials} ${state.personal.prefix} ${state.personal.lastName}`
			}
		},
		email: state => state.contact.email ?? "",
		telephone: state => state.contact.telephone ?? "",
		legalCommunication: state => state.communication as string
	},
	mutations: {
		hydrate(state, data: Policyholder) {
			Object.assign(state, data)
		},
		setRiskProfileCode(state, data: string) {
			state.riskProfileCode = data
		},
		setNetYearlySalary(state, data: number) {
			state.netYearlySalary = data
		},
		setGrossYearlySalary(state, data: number) {
			state.grossYearlySalary = data
		},
		setFiscalRoomYearly(state, data: number) {
			state.fiscalRoomYearly = data
		},
		setFiscalRoomOneTime(state, data: number) {
			state.fiscalRoomOneTime = data
		},
		setEmail(state, email: string) {
			state.contact.email = email
		},
		setTelephone(state, telephone: string) {
			state.contact.telephone = telephone
		},
		setLegalCommunication(state, legalCommunication: string) {
			state.communication = legalCommunication as LegalCommunication
		}
	},
	namespaced: true,
	state: policyholderState
}

export default policyholder
