import {Gender, MaritalStatus, PhoneType, Status} from "lib/types/personal/personal"
import {
	ExchangeTarget,
	Channel,
	LegalCommunication,
	StatePensionSource,
	ExternalCoverType,
	ExternalCoverSource,
	ExternalCoverCause,
	PrivateBenefitSource,
	PrivateBenefitType
} from "lib/types/personal/participant"
import {
	FiscalType,
	Beneficiary,
	Trigger,
	CivilStatusReason,
	CivilStatusType,
	PolicyStatus,
	AccrualCoverType,
	InsuranceCoverType,
	AccrualCoverCause,
	InsuranceCoverCause,
	ReserveCoverCause,
	EmploymentStatus
} from "lib/types/personal/policyholder"
import {ConversionFunction} from "lib/types/import"

// Personal

export const gender: ConversionFunction<Gender> = (v: any) =>
	["FEMALE", "MALE", "THIRD", "UNKNOWN"].includes(v) ? v : undefined

export const maritalStatus: ConversionFunction<MaritalStatus> = (v: any) =>
	["LegalCohabitation", "LivingTogether", "Married", "Divorced", "Single", "Unknown"].includes(v) ? v : undefined

export const phoneType: ConversionFunction<PhoneType> = (v: any) =>
	["Mobile", "Landline", "Other", "Unknown"].includes(v) ? v : undefined

export const status: ConversionFunction<Status> = (v: any) =>
	["Disabled", "Active", "Inactive", "Pensioner", "Unknown"].includes(v) ? v : undefined

// Participant

export const legalCommunication: ConversionFunction<LegalCommunication> = (v: any) =>
	["ONLINE", "OFFLINE"].includes(v) ? v : undefined

export const channel: ConversionFunction<Channel> = (v: any) =>
	["EMAIL", "SMS", "LETTER", "GOVERNMENT INBOX"].includes(v) ? v : undefined

export const statePensionSource: ConversionFunction<StatePensionSource> = (v: any) =>
	["REGISTER", "USER", "CALCULATION"].includes(v) ? v : undefined

export const externalCoverType: ConversionFunction<ExternalCoverType> = (v: any) =>
	["DEFINED_BENEFIT", "DEFINED_CONTRIBUTION"].includes(v) ? v : undefined

export const externalCoverCause: ConversionFunction<ExternalCoverCause> = (v: any) =>
	["EMPLOYMENT", "EQUALIZATION", "CONVERSION"].includes(v) ? v : undefined

export const externalCoverSource: ConversionFunction<ExternalCoverSource> = (v: any) =>
	["REGISTER", "USER"].includes(v) ? v : undefined

export const exchangeTarget: ConversionFunction<ExchangeTarget> = (v: any) =>
	["RETIREMENT", "PARTNER"].includes(v) ? v : undefined

export const privateBenefitType: ConversionFunction<PrivateBenefitType> = (v: any) =>
	["ANNUITY", "FISCAL_SAVINGS", "PENSION", "SAVINGS", "INVESTED_CAPITAL", "OTHER"].includes(v) ? v : undefined

export const privateBenefitSource: ConversionFunction<PrivateBenefitSource> = (v: any) =>
	["USER"].includes(v) ? v : undefined

// POLICYHOLDER
export const civilStatusReason: ConversionFunction<CivilStatusReason> = (v: any) =>
	["SEPARATION", "DIVORCE", "DECEASE"].includes(v) ? v : undefined

export const civilStatusType: ConversionFunction<CivilStatusType> = (v: any) =>
	["SINGLE", "COHABITATION", "LEGAL_COHABITATION", "CIVILPARTNERSHIP", "MARRIED"].includes(v) ? v : undefined

export const policyStatus: ConversionFunction<PolicyStatus> = (v: any) =>
	["PENDING", "ACTIVE", "INACTIVE", "PAYING"].includes(v) ? v : undefined

export const employmentStatus: ConversionFunction<EmploymentStatus> = (v: any) =>
	["ACTIVE", "INACTIVE"].includes(v) ? v : undefined

export const beneficiary: ConversionFunction<Beneficiary> = (v: any) =>
	["POLICYHOLDER", "PARTNER", "CHILD"].includes(v) ? v : undefined

export const trigger: ConversionFunction<Trigger> = (v: any) =>
	["RETIREMENT", "DECEASE", "DISABILITY"].includes(v) ? v : undefined

export const accrualCoverType: ConversionFunction<AccrualCoverType> = (v: any) =>
	["PENSION"].includes(v) ? v : undefined

export const insuranceCoverType: ConversionFunction<InsuranceCoverType> = (v: any) =>
	["PENSION", "GAP"].includes(v) ? v : undefined

export const fiscalType: ConversionFunction<FiscalType> = (v: any) =>
	["GROSS", "NET"].includes(v) ? v : undefined

export const accrualCoverCause: ConversionFunction<AccrualCoverCause> = (v: any) =>
	["EMPLOYMENT", "VOLUNTARY", "EQUALIZATION", "CONVERSION"].includes(v) ? v : undefined

export const insuranceCoverCause: ConversionFunction<InsuranceCoverCause> = (v: any) =>
	["EMPLOYMENT", "VOLUNTARY"].includes(v) ? v : undefined

export const reserveCoverCause: ConversionFunction<ReserveCoverCause> = (v: any) =>
	["EMPLOYMENT", "VOLUNTARY", "EQUALIZATION", "CONVERSION"].includes(v) ? v : undefined
